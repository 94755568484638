import {
    PAGE_STARTING,
    PAGE_SUCCESSFULL,
    EDIT_PAGE_SUCCESSFULL,
    PAGE_FAILED
} from '../actions/page';

import updateObject from "../updateObject";

const initialState = {
    inLoading: false,
    error: "",
    data: []
};

export function pageStart(state, action) {
    return updateObject(state, {
        inLoading: true
    });
}

export function pageSuccess(state, action) {
    return updateObject(state, {
        inLoading: false,
        data: action.payload
    });
}

export function pageEditSuccess(state, action) {
    console.log([
        ...state.data.filter(item => item.name != action.payload.name),
        {
            id: state.data.find(item => item.name == action.payload.name)?.id,
            ...action.payload
        }
    ])
    return updateObject(state, {
        inLoading: false,
        data: [
            ...state.data.filter(item => item.name != action.payload.name),
            {
                id: state.data.find(item => item.name == action.payload.name)?.id,
                ...action.payload
            }
        ]
    });
}

export function pageFailed(state, action) {
    return updateObject(state, {
        inLoading: false,
        error: action.payload
    });
}

export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case PAGE_STARTING: return pageStart(state, action);
        case PAGE_SUCCESSFULL: return pageSuccess(state, action);
        case EDIT_PAGE_SUCCESSFULL: return pageEditSuccess(state, action);
        case PAGE_FAILED: return pageFailed(state, action);

        default: return state;
    }
}