import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet';

import { LandingSlider, ProductCard } from "../common";
import { editPage } from "../../redux/actions/page";
import { setFilterCategories } from "../../redux/actions/filters";
import { Link } from "react-router-dom/cjs/react-router-dom";

const EditableCategories = ({ accountType, title, filter, category, label }) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const dispatch = useDispatch();

    const products = useSelector(state => state.products.data);
    const categories = useSelector(state => state.categories.data);

    const filters = [
        { value: 'none', label: 'Niciuna' },
        { value: 'promo', label: 'Promotii' },
        { value: 'new', label: 'Produse noi' },
        { value: 'upprice', label: 'Pret crescator' },
        { value: 'downprice', label: 'Pret descrescator' },
        { value: 'stock', label: 'Stoc disponibil' }
    ];

    const [titleState, setTitleState] = useState(title);
    const [filterState, setFilterState] = useState(filter);
    const [categoryState, setCategoryState] = useState(category);

    const [editMode, setEditMode] = useState(false);

    return (
        <center className={isMobile ? "d-flex flex-column input__group m-auto w-100" : "d-flex flex-column input__group m-auto w-fit"}>
            {
                editMode ?
                <>
                    <input className="text-center" value={titleState} onChange={e => setTitleState(e.target.value)} />

                    <div className="d-flex flex-row justify-content-center gap-3">
                        <div>
                            <label>Categorie:</label>
                            <select
                                value={categoryState}
                                onChange={(e) => setCategoryState(e.target.value)}
                            >
                                {
                                    categories?.map((value, idx) => (
                                        <option key={idx} value={value.id}>{value.title}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div>
                            <label>Filtru:</label>
                            <select
                                value={filterState}
                                onChange={(e) => setFilterState(e.target.value)}
                            >
                                {
                                    filters?.map((value, idx) => (
                                        <option key={idx} value={value.value}>{value.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </>
                :
                <>
                    <h1 className={isMobile ? "text-center fs-6 text-black bold landing__title m-auto" : "text-center fs-4 text-black bold landing__title m-auto"}>{titleState}</h1>
                    <div className={isMobile ? "products" : "products m-2"}>
                        {
                            products?.sort((elm1, elm2) => {
                                switch(filterState) {
                                    case 'upprice':
                                        return (elm1.price - elm2.price);
                                    case 'downprice':
                                        return (elm2.price - elm1.price);
                                    case 'stock':
                                        return (elm2.stock - elm1.stock);
                                    case 'new':
                                        return (elm2.id - elm1.id);
                                    case 'promo':
                                        return (elm2.promo - elm1.promo);
                                }
                            }).filter((elm) => categories.find(value => value.id == categoryState)).slice(0, 4).map(
                                (value, idx) => (
                                    <ProductCard key={idx} product={value} />
                                )
                            )
                        }
                    </div>
                </>
            }

            {
                accountType == "master"  &&
                (
                    editMode ?
                    <div className="d-flex flex-row m-auto">
                        <div className="m-4 user-select-none">
                            <span className="material-icons-outlined pointer" onClick={() => {
                                setEditMode(!editMode);

                                dispatch(editPage({
                                    name: `landing_products_title${label}`,
                                    value: titleState
                                }))

                                dispatch(editPage({
                                    name: `landing_products_category${label}`,
                                    value: categoryState
                                }))

                                dispatch(editPage({
                                    name: `landing_products_filter${label}`,
                                    value: filterState
                                }))
                            }}>done</span>
                        </div>
                        <div className="m-4 user-select-none">
                            <span className="material-icons-outlined pointer" onClick={() => {setEditMode(!editMode); setTitleState(title); setCategoryState(category); setFilterState(filter)}}>close</span>
                        </div>
                    </div>
                    :
                    <div className="m-4 user-select-none">
                        <span className="material-icons-outlined pointer" onClick={() => setEditMode(!editMode)}>edit</span>
                    </div>
                )
            }
        </center>
    )
}

const EditableTexts = ({ accountType, texts }) => {
    const dispatch = useDispatch();

    const [textsState, setTextsState] = useState(texts);
    const [editMode, setEditMode] = useState(false);

    return (
        <center className="d-flex flex-column input__group m-auto w-100">
            {
                Object.keys(textsState)?.map((textIdx, idx) => (
                    <>
                        {
                            editMode ?
                            <input key={idx} className="text-center" value={textsState[textIdx]} onChange={e => setTextsState({...textsState, [textIdx]: e.target.value})} />
                            :
                            <div key={idx}>{textsState[textIdx]}</div>
                        }
                    </>
                ))
            }

            {
                accountType == "master"  &&
                (
                    editMode ?
                    <div className="d-flex flex-row m-auto">
                        <div className="m-4 user-select-none">
                            <span className="material-icons-outlined pointer" onClick={() => {
                                setEditMode(!editMode);

                                Object.keys(textsState)?.map((textIdx, idx) => {
                                    dispatch(editPage({
                                        value: textsState[textIdx],
                                        name: textIdx
                                    }))
                                })
                            }}>done</span>
                        </div>
                        <div className="m-4 user-select-none">
                            <span className="material-icons-outlined pointer" onClick={() => {setEditMode(!editMode); setTextsState(texts)}}>close</span>
                        </div>
                    </div>
                    :
                    <div className="m-4 user-select-none">
                        <span className="material-icons-outlined pointer" onClick={() => setEditMode(!editMode)}>edit</span>
                    </div>
                )
            }
        </center>
    )
}

const Home = () => {
    const dispatch = useDispatch();
    const page = useSelector(state => state.page);
    const account = useSelector(state => state.account.data);
    const categories = useSelector(state => state.categories.data);

    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name === parameter) {
                return param.type == "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        !page.inLoading && page.data.length > 0 ? <>
            <Helmet>
                <title>Sufyi Embroidery.</title>
            </Helmet>
            <LandingSlider image={require("../../assets/background.gif").default} landing={true} />


            <center className="d-flex flex-column m-auto w-fit">
                <h1 className={isMobile ? "text-center fs-6 text-black bold landing__title m-auto" : "text-center fs-4 text-black bold landing__title m-auto"}>Shop by desired category</h1>
                <div className="landing__categories m-2">
                    <div className="category">
                        <img className="background" src={require("../../assets/hoodies.png").default} alt='index' />
                        <Link className="content" to="/products" onClick={() => dispatch(setFilterCategories([categories?.find( category => category.title == "Hoodie")]))}>
                            <div className="title">Hoodies</div>
                            <div className="button m-0">See category</div>
                        </Link>
                    </div>
                    <div className="category">
                        <img className="background" src={require("../../assets/t-shirts.png").default} alt='index' />
                        <Link className="content" to="/products" onClick={() => dispatch(setFilterCategories([categories?.find( category => category.title == "T-Shirt")]))}>
                            <div className="title">T-SHIRTS</div>
                            <div className="button m-0">See category</div>
                        </Link>
                    </div>
                    <div className="category">
                        <img className="background" src={require("../../assets/crewnecks.png").default} alt='index' />
                        <Link className="content" to="/products" onClick={() => dispatch(setFilterCategories([categories?.find( category => category.title == "Crewnecks")]))}>
                            <div className="title">Crewnecks</div>
                            <div className="button m-0">See category</div>
                        </Link>
                    </div>
                </div>
            </center>

            <EditableCategories accountType={account?.type} label={1} title={getPageParameter("landing_products_title1")} filter={getPageParameter("landing_products_filter1")} category={getPageParameter("landing_products_category1")} />

            <LandingSlider image={require("../../assets/background--customize.png").default} landing={false} />

            <EditableCategories accountType={account?.type} label={2} title={getPageParameter("landing_products_title2")} filter={getPageParameter("landing_products_filter2")} category={getPageParameter("landing_products_category2")} />
        </>
        :
        <></>
    );
}

export default Home;