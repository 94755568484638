import React, { useState, useEffect } from 'react';
import  { useLocation, Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";

import { Layout } from '../common/utils';
import { ImageUploader} from '../common';
import { editProduct } from '../../redux/actions/product';

const Index = () => {
    const search = new URLSearchParams(useLocation().search);

    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);
    const categories = useSelector(state => state.categories.data);
    const products = useSelector(state => state.products.data);

    const [product, setProduct] = useState({
        title: '',
        description: '',
        price: 0,
        stock: 0,
        content: {
            promo: 0,
            sizes: [],
            colors: []
        },
        categories: [],
        images: []
    });
    
    const sizes = [
        { id: 1, name: "XS" },
        { id: 2, name: "S" },
        { id: 3, name: "M" },
        { id: 4, name: "L" },
        { id: 5, name: "XL" },
        { id: 5, name: "Freesize" }
    ]
    
    const colors = [
        { id: 1, value: "red" },
        { id: 2, value: "blue" },
        { id: 3, value: "green" },
        { id: 4, value: "yellow" },
        { id: 5, value: "orange" },
        { id: 6, value: "purple" },
        { id: 7, value: "pink" },,
        { id: 8, value: "nude" },
        { id: 9, value: "brown" },
        { id: 10, value: "grey" },
        { id: 11, value: "black" },
        { id: 12, value: "white" }
    ]
    
    useEffect(() => {
        if (products?.find(product => product?.id == search.get("id"))) {
            let product = products?.find(product => product?.id == search.get("id"));

            product = {
                ...product,
                images: product?.images,
                content: product?.content
            }

            setProduct(product);
        }
    }, [products]);

    return (
        <>
            <Layout>
                {
                    account && account?.type == "master" ?
                    <div className="product__container m-auto p-5">
                            <div className="container">
                                <div className='label'>Images:</div>
                                <div className='d-flex flex-row justify-content-start flex-wrap gap-2'>
                                    {
                                        product?.images?.map((image, index) => (
                                            <div key={index} className='d-flex flex-column justify-content-center align-items-center'>
                                                <ImageUploader value={image} setImage={(image) => {
                                                    const images = product?.images;

                                                    images[index] = image;

                                                    setProduct({ ...product, images })
                                                }} />
                                                <div className='button' onClick={() => {
                                                    const images = product?.images.filter((image, i) => i != index);

                                                    setProduct({ ...product, images })
                                                }}>Remove</div>
                                            </div>
                                        ))
                                    }
                                    <ImageUploader setImage={(image) => {
                                        const images = product?.images || [];

                                        images.push(image);

                                        setProduct({ ...product, images });
                                    }} />
                                </div>
                                <div className='d-flex flex-column gap-3 h-100'>
                                    <div>
                                        <div className='label'>Title:</div>

                                        <div className='value input__group w-100 p-0 m-0'>
                                            <input type="text" name="title" value={product.title} onChange={(e) => setProduct({ ...product, title: e.target.value })} />
                                        </div>
                                    </div>

                                    <div>
                                        <div className='label'>Categories:</div>

                                        <div className='value input__group p-0 m-0'>
                                            {
                                                product?.categories?.map((category, idx) => (
                                                    <div key={idx} className='d-flex flex-row justify-content-between text-black'>
                                                        {categories?.find(value => value.id == category)?.title}
                                                        <span className="material-icons-outlined pointer" onClick={() => {
                                                            const categories = product?.categories?.filter((value) => value != category);

                                                            setProduct({ ...product, categories: categories });
                                                        }}>close</span>
                                                    </div>
                                                ))
                                            }

                                            <div className='d-flex flex-row justify-content-center gap-5 py-2 pointer'>
                                                <select className='w-100' onChange={(e) => {
                                                    setProduct({ ...product, category: e.target.value });
                                                }}>
                                                    <option value="none">None</option>
                                                    {
                                                        categories?.filter(category => category.type == "primary")?.map((category, idx) => (
                                                            <option key={idx} value={category.id}>{category.title}</option>
                                                        ))
                                                    }
                                                </select>
                                                <span className="material-icons-outlined" onClick={() => {
                                                    if (product?.category == 'none' || product?.category == undefined) {
                                                        alert('Select a category first.');
                                                        return;
                                                    }

                                                    setProduct({ ...product, categories: [...product?.categories || [], product?.categories.find(category => product?.category == category.id)?.id == undefined ? categories.find(category => product?.category == category.id)?.id : undefined].filter(value => value != undefined)});
                                                }}>add</span>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        categories?.filter(category => category.type == "secondary")?.map((category, idx) => (
                                            <div key={idx}>
                                                <div className='label'>{category.title}:</div>

                                                <div className='value input__group p-0 m-0'>
                                                    <div className='d-flex flex-row justify-content-center gap-5 py-2 pointer'>
                                                        <select className='w-100' onChange={(e) => {
                                                            setProduct({ ...product, subcategory: e.target.value });
                                                        }}>
                                                            <option value="none">None</option>
                                                            {
                                                                categories?.filter(scategory => scategory.pid == category.id)?.map((category, idx) => (
                                                                    <option key={idx} value={category.id}>{category.title}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <span className="material-icons-outlined" onClick={() => {
                                                            if (product?.subcategory == 'none' || product?.subcategory == undefined) {
                                                                alert('Select a category first.');
                                                                return;
                                                            }
                                                            
                                                            setProduct({ ...product, categories: [...product?.categories || [], product?.categories.find(category => product?.subcategory == category.id)?.id == undefined ? categories.find(category => product?.subcategory == category.id)?.id : undefined].filter(value => value != undefined) });
                                                        }}>add</span>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                    }

                                    <div>
                                        <div className='label'>Description:</div>

                                        <div className='value input__group p-0 m-0 w-100'>
                                            <textarea type="text" name="description" value={product.description} onChange={(e) => setProduct({ ...product, description: e.target.value })} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='label'>Price:</div>

                                        <div className='value input__group p-0 m-0'>
                                            <input type="text" name="price" value={product.price} onChange={(e) => setProduct({ ...product, price: e.target.value })} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='label'>Promotion(%):</div>

                                        <div className='value input__group p-0 m-0'>
                                            <input type="text" name="promotion" value={product?.content?.promo} onChange={(e) => setProduct({ ...product, content: { ...product?.content, promo: e.target.value } })} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='label'>Stock:</div>

                                        <div className='value input__group p-0 m-0'>
                                            <input type="text" name="stock" value={product?.stock} onChange={(e) => setProduct({ ...product, stock: e.target.value })} />
                                        </div>
                                    </div>

                                    <div>
                                        <div className='label'>Sizes:</div>

                                        <div className='value sizes'>
                                            {
                                                sizes.map((size, idx) => (
                                                    <div key={idx} className={'size' + (product?.content?.sizes?.includes(size.name) ? ' active' : '')}
                                                        onClick={() => {
                                                            let sizes = product?.content?.sizes || [];

                                                            if (sizes?.includes(size.name)) {
                                                                sizes = sizes.filter(s => s != size.name);
                                                            }
                                                            else {
                                                                sizes.push(size.name);
                                                            }

                                                            setProduct({ ...product, content: { ...product?.content, sizes } });
                                                        }}
                                                    >

                                                        <div className="value">
                                                            {size.name}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className='label'>Colors:</div>

                                        <div className='value colors'>
                                            {
                                                colors.map((color, idx) => (
                                                    <div key={idx} className={(product?.content?.colors?.includes(color.value) ? ' active' : '')}>
                                                        <div className={`color ${color.value}`}
                                                            onClick={() => {
                                                                let colors = product?.content?.colors || [];

                                                                if (colors?.includes(color.value)) {
                                                                    colors = colors.filter(s => s != color.value);
                                                                }
                                                                else {
                                                                    colors.push(color.value);
                                                                }

                                                                setProduct({ ...product, content: { ...product?.content, colors } })
                                                            }}
                                                        >
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className='button' onClick={async () => {
                                        if (product?.title == '' || product?.title == undefined) {
                                            alert('Insert a title.');
                                        }
                                        else if (product?.categories.length == 0 || product?.categories == undefined) {
                                            alert('Select at least one category.');
                                        }
                                        else if (product?.description == '' || product?.description == undefined) {
                                            alert('Insert a description.');
                                        }
                                        else if (product?.price == '' || product?.price == undefined) {
                                            alert('Insert a price.');
                                        }
                                        else if (product?.stock == '' || product?.stock == undefined) {
                                            alert('Insert a stock.');
                                        }
                                        else if (product?.images?.length == 0 || product?.images == undefined) {
                                            alert('Select images.');
                                        }
                                        else if (product?.images?.length > 5) {
                                            alert('Select a maximum of 5 images.');
                                        }
                                        else {
                                            dispatch(editProduct(product));
                                        }
                                    }}>
                                        Aplica
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                    <Redirect to="/" />
                }
            </Layout>
        </>
    );
};

export default Index;